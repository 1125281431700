import React from "react";
import AccountForm from "../components/Forms/AccountForm";

import "./page.css";

class Account extends React.Component {
  render() {
    return (
      <div className="container">
        <AccountForm />
      </div>
    );
  }
}

export default Account;

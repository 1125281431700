import React, { Component } from "react";

class Home extends Component {
  token = localStorage.getItem("token");

  getUser = () => {
    fetch("https://api.uu.vojtechpetrasek.com/v3/user", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
  };

  render() {
    return (
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="card-body p-5 text-center">
            <h3 className="mb-5 text-white">Welcome to Solarbeam Cloud</h3>
            <p className="mb-5 text-white">
              Smart solar panel monitoring system for everyone.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;

import React, { useEffect, useState } from "react";

const ErrorToast = (props) => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
      props.handler(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [props]);

  return (
    <>
      <div class="toast-container top-0 end-0 pt-5">
        <div
          class={show ? "fade toast show " : "fade toast"}
          role="alert"
          aria-live="assertive"
          aria-atomic="true">
          <div class="toast-header text-bg-danger">
            <strong class="me-auto">Error</strong>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"></button>
          </div>
          <div class="toast-body text-dark">{props.text}</div>
        </div>
      </div>
    </>
  );
};

export default ErrorToast;

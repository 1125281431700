import React from "react";
import UserForm from "../components/UserForm/UserForm";

class AddUser extends React.Component {
  render() {
    return (
      <>
        <div className="container-fluid row">
          <UserForm />
        </div>
      </>
    );
  }
}

export default AddUser;

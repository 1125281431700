import React, { useState, useEffect } from "react";
import {
  ComposedChart,
  Area,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  ResponsiveContainer,
} from "recharts";
const moment = require("moment");

const api_url = process.env.REACT_APP_API_URL;

export const LineGraph = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [start, setStart] = useState(
    moment().subtract(3, "hours").format("YYYY-MM-DDTHH:mm:ss.SSS")
  );

  useEffect(() => {
    setLoading(true);

    getHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  const handleSelect = (e) => {
    console.log(e.target.value);
    console.log("select");
    setStart(
      moment()
        .subtract(Number(e.target.value) + 1, "hours")
        .format("YYYY-MM-DDTHH:mm:ss.SSS")
    );
    console.log("start", start);
  };

  const getHistory = () => {
    const token = localStorage.getItem("token");
    fetch(api_url + "/device/" + props.id + "/history?start=" + start + "Z", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          console.log(res);
        }
      })
      .then((device_data) => {
        setData(device_data.data);
        setLoading(false);
        console.log("graph_data", device_data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const dateFormater = (tickItem) => {
    return moment(tickItem).format("DD.MM HH:mm");
  };

  const brushFormater = (tickItem, value) => {
    console.log("tickItem", tickItem);
    console.log("value", value);
    return moment(data[tickItem].time).format("DD.MM HH:mm");
  };

  console.log("graph", props);
  return (
    <div className="card border-left-primary shadow p-2">
      <div className="card-body">
        <div className="row no-gutters align-items-center">
          <div className="col mr-2">
            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
              Powers
            </div>
          </div>
          <div
            className="col col-auto input-group"
            style={{ maxWidth: "200px" }}>
            <span className="input-group-text">Last</span>
            <select
              className="form-select"
              id="inputGroupSelect01"
              onChange={handleSelect}>
              <option value="1">1 hour</option>
              <option value="2">2 hours</option>
              <option value="3" selected>
                3 hours
              </option>
              <option value="6">6 hours</option>
              <option value="12">12 hours</option>
              <option value="24">24 hours</option>
              <option value="48">48 hours</option>
            </select>
          </div>
        </div>
      </div>
      {loading ? (
        <div>
          Loading...
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"></span>
        </div>
      ) : (
        <ResponsiveContainer width="100%" height="100%" aspect={2.5}>
          <ComposedChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}>
            <Brush tickFormatter={brushFormater} />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="time"
              tick={{ fontSize: "10px" }}
              angle={-45}
              height={60}
              dy={20}
              ticksMargin={10}
              tickFormatter={dateFormater}
            />
            <YAxis unit={"W"} tick={{ fontSize: "12px" }}>
              <Label
                value="W"
                position="insideLeft"
                angle={-90}
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <Tooltip labelFormatter={dateFormater} />
            <Legend />

            <Area
              type="monotone"
              dataKey="total_load"
              connectNulls
              stroke="#195896"
              fill="#72b0eb"
            />
            <Area
              type="monotone"
              dataKey="ac_active_power"
              connectNulls
              stroke="#199651"
              fill="#72e6a6"
            />
            <Area
              type="monotone"
              dataKey="total_backup_load"
              connectNulls
              stroke="#bba420"
              fill="#e6d575"
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

import React from "react";
import { SpotGraph } from "./Graph";

export const Spot = (props) => {
  return (
    <>
      <div className="container-fluid" style={{ height: "60vh" }}>
        <SpotGraph id={props.id} />
      </div>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const api_url = process.env.REACT_APP_API_URL;

const Devices = () => {
  const navigate = useNavigate();
  const [devices, setDevices] = useState([]);
  const [sharedDevices, setSharedDevices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = () => {
    const token = localStorage.getItem("token");
    fetch(api_url + "/devices", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 401) {
          navigate("/401");
        } else if (res.status === 404) {
          navigate("/404");
        } else if (res.status === 200) {
          return res.json();
        } else {
          alert("Something went wrong, please contact administrator.");
        }
      })
      .then((data) => {
        setDevices(data.active_devices);
        setSharedDevices(data.shared_devices);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const showDevices = () => {
    return (
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">id</th>
            <th scope="col">Name</th>
            <th scope="col">Owner</th>
          </tr>
        </thead>
        <tbody>
          {devices.map((_, index) => {
            return (
              <>
                <tr>
                  <th scope="row">
                    <Link to={"/device/" + devices[index]._id}>
                      <button type="button" className="btn btn-success m-1">
                        Show
                      </button>
                    </Link>
                  </th>
                  <th scope="row">{devices[index]._id}</th>
                  <th scope="row">{devices[index].name}</th>
                  <th scope="row">{devices[index].owner_name}</th>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    );
  };

  const showSharedDevices = () => {
    return (
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">id</th>
            <th scope="col">Name</th>
            <th scope="col">Owner</th>
          </tr>
        </thead>
        <tbody>
          {sharedDevices.map((_, index) => {
            return (
              <>
                <tr>
                  <th scope="row">
                    <Link to={"/device/" + sharedDevices[index]._id}>
                      <button type="button" class="btn btn-success m-1">
                        Show
                      </button>
                    </Link>
                  </th>
                  <th scope="row">{sharedDevices[index]._id}</th>
                  <th scope="row">{sharedDevices[index].name}</th>
                  <th scope="row">{sharedDevices[index].owner_name}</th>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    );
  };
  if (loading) {
    return (
      <div className="container">
        <h1 className="text-white text-center pt-5">Loading...</h1>
      </div>
    );
  } else {
    return (
      <div className="container">
        <h1 className="text-white text-center pt-5">Devices</h1>
        <button
          type="button"
          class="btn btn-success mt-3 mb-3"
          data-bs-toggle="modal"
          data-bs-target="#AddGateway">
          Add Device
        </button>
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne">
                My Devices
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingOne">
              <div class="accordion-body">{showDevices()}</div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseTwo">
                Shared devices
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingTwo">
              <div class="accordion-body">{showSharedDevices()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default Devices;

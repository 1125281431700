import React, { useState, useEffect } from "react";
import Configurator from "../components/Configurator/Configurator";
import Debug from "../components/Configurator/Debug";

const DeviceConfig = () => {
  const [usbPort, setUsbPort] = useState(null);
  const [usbDataArray, setUsbDataArray] = useState([]);
  const [closePort, setClosePort] = useState(false);

  const connectUSBDevice = async () => {
    const vendorId = 0x0403;
    const productId = 0x6015;
    setClosePort(false);
    try {
      const port = await navigator.serial.requestPort({
        filters: [{ usbVendorId: vendorId, usbProductId: productId }],
      });

      await port.open({ baudRate: 115200 });
      await port.setSignals({ dataTerminalReady: true });

      // Perform further actions with the selected serial port
      setUsbPort(port);
    } catch (error) {
      console.error("Error requesting serial port:", error);
    }
  };

  const disconnectUSBDevice = async () => {
    try {
      if (usbPort) {
        await usbPort.close();
        setUsbPort(null);
        setClosePort(true);
      }
    } catch (error) {
      console.error("Error closing serial port:", error);
    }
  };

  const readData = async () => {
    try {
      const reader = usbPort.readable.getReader();
      let buffer = "";
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }
        const data = new TextDecoder().decode(value);
        console.log("data", data);

        buffer += data;
        console.log("buffer", buffer);

        if (data.includes("\r")) {
          //const timestamp = new Date().toLocaleTimeString();
          buffer = buffer.replace("\n", "");

          //let out = timestamp + ": " + buffer;

          setUsbDataArray((array) => [...array, data]);
          buffer = "";
        }
      }
    } catch (error) {
      console.error("Error reading from serial port:", error);
    }
  };

  const writeData = async (data) => {
    console.log("writeData", data);
    try {
      const writer = usbPort.writable.getWriter();
      const encoder = new TextEncoder();
      data = encoder.encode(data + "\r");
      await writer.write(data);
      await writer.close();
    } catch (error) {
      console.error("Error writing to serial port:", error);
    }
  };

  useEffect(() => {
    if (usbPort && !closePort) {
      readData();
    }

    return () => {
      // Cleanup
      if (usbPort) {
        usbPort.close();
      }
    };
    // eslint-disable-next-line
  }, [usbPort, closePort]);

  return (
    <div className="container mt-5">
      <button className="btn btn-primary" onClick={connectUSBDevice}>
        Připojit zařízení USB
      </button>
      <button className="btn btn-danger" onClick={disconnectUSBDevice}>
        Odpojit zařízení USB
      </button>

      <div className="mt-5">
        {usbPort && (
          <>
            <Configurator writeData={writeData} />
            <Debug usbData={usbDataArray} />
          </>
        )}
      </div>
    </div>
  );
};

export default DeviceConfig;

import React, { Component } from "react";

import RegisterForm from "../components/Forms/RegisterForm";

import "./page.css";

class RegisterPage extends Component {
  render() {
    return (
      <div className="container">
        <RegisterForm />
      </div>
    );
  }
}

export default RegisterPage;

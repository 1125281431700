import React, { Component } from "react";

class Documentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docs: [],
    };
  }
  render() {
    return (
      <div className="container position-relative" style={{ height: "85vh" }}>
        <iframe
          title="Swagger UI"
          src="https://dev.api.solarbeam.vojtechpetrasek.com/docs/"
          height="100%"
          width="100%"
        />
      </div>
    );
  }
}

export default Documentation;

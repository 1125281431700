import React, { useState, useEffect } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
const moment = require("moment");

const api_url = process.env.REACT_APP_API_URL;

export const SpotGraph = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [treshold, setTreshold] = useState(90);

  useEffect(() => {
    setLoading(true);

    getHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("treshold update data", treshold);
    if (data.length > 0) {
      console.log("data.price", data);
      setData(compareData(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treshold]);

  const compareData = (data) => {
    console.log("data", data);
    data.forEach((element) => {
      if (element.value > treshold) {
        element.limitation = 0;
      } else {
        element.limitation = 100;
      }
    });
    return data;
  };

  const dateFormater = (tickItem) => {
    return moment(tickItem).format("DD.MM HH:mm");
  };

  const tootlTipFormater = (value, name, props) => {
    if (name === "value") {
      return ["€/MWh", value, props];
    } else {
      if (value === 0) {
        return ["off", name, props];
      } else {
        return ["on", name, props];
      }
    }
  };

  const getHistory = () => {
    const token = localStorage.getItem("token");
    const start = moment()
      .startOf("day")
      .subtract(1, "hours")
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    const stop = moment()
      .endOf("day")
      .subtract(1, "hours")
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    console.log("start", start);
    fetch(api_url + "/spot/CZ?start=" + start + "Z?stop=" + stop + "Z", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          console.log(res);
        }
      })
      .then((device_data) => {
        const tmp = compareData(device_data.price);
        setData(tmp);
        setLoading(false);
        console.log("device_data", device_data);
        console.log("data", tmp);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="card border-left-primary shadow h-100 p-2">
      <div className="card-body">
        <div className="row no-gutters align-items-center">
          <div className="col mr-2">
            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
              SPOT
            </div>
          </div>
        </div>
      </div>
      <div>
        <label htmlFor="treshold">Treshold</label>
        <input
          type="number"
          value={treshold}
          onChange={(e) => setTreshold(e.target.value)}
        />
      </div>
      {loading ? (
        <div>
          Loading...
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"></span>
        </div>
      ) : (
        <ResponsiveContainer width="100%" height="80%">
          <ComposedChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="time"
              tick={{ fontSize: "10px" }}
              angle={-45}
              height={60}
              dy={20}
              interval={0}
              tickFormatter={dateFormater}
            />
            <YAxis unit={"€"} tick={{ fontSize: "12px" }} yAxisId={"left"}>
              <Label
                value="€/MWh"
                position="insideLeft"
                angle={-90}
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <YAxis
              height={60}
              unit={"%"}
              tick={{ fontSize: "12px" }}
              yAxisId={"right"}
              orientation="right">
              <Label
                value="Limitation"
                position="insideRight"
                angle={-90}
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <ReferenceLine
              y={treshold}
              label="Treshold"
              stroke="red"
              yAxisId={"left"}
            />
            <Tooltip
              formatter={tootlTipFormater}
              labelFormatter={dateFormater}
            />
            <Legend
              formatter={(name) => (name === "value" ? "€/MWh" : "limitation")}
            />
            <Area
              type="monotone"
              dataKey="value"
              connectNulls
              stroke="#195896"
              fill="#72b0eb"
              yAxisId={"left"}
            />
            <Line
              dot={false}
              type="monotone"
              dataKey="limitation"
              connectNulls
              stroke="#d70a0a"
              fill="#bf6666"
              yAxisId={"right"}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

import React, { useState } from "react";
import Switch from "./switch";

export const Settings = () => {
  const [exportLimitation, setExportLimitation] = useState(false);
  const [exportLimitationValue, setExportLimitationValue] = useState(0);

  const handleExportLimitation = () => {
    console.log("Export limitation: " + !exportLimitation);
    setExportLimitation(!exportLimitation);
  };

  const handleExportLimitationValue = (e) => {
    console.log("Export limitation value: " + e.target.value);
    setExportLimitationValue(e.target.value);
  };

  return (
    <>
      <div className="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">Settings</h1>
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne">
                Spot settings
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingOne">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        Enable export limitation
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-2">
                    <Switch
                      isToggled={exportLimitation}
                      onToggle={handleExportLimitation}
                    />
                  </div>
                </div>
                <div className="row">
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      Export limitation
                    </span>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Export limitation"
                      aria-label="Export limitation"
                      aria-describedby="basic-addon2"
                      value={exportLimitationValue}
                      onChange={handleExportLimitationValue}
                    />
                    <span class="input-group-text" id="basic-addon2">
                      €/kWh
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import React, { Component } from "react";

import { LoginForm } from "../components/Forms/LoginForm";

import "./page.css";

class LoginPage extends Component {
  render() {
    return (
      <div className="container">
        <LoginForm />
      </div>
    );
  }
}

export default LoginPage;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { Dashboard } from "../components/Dashboard/Dashboard";
import { Settings } from "../components/Settings/Settings";
import { Spot } from "../components/Spot/Spot";
import { Graphs } from "../components/Graphs/Graphs";
import { Statistics } from "../components/Statistics/Statistics";
const api_url = process.env.REACT_APP_API_URL;

const Device = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [device, setDevice] = useState({});
  const [loading, setLoading] = useState(true);

  const [menu, setMenu] = useState("Dashboard");

  useEffect(() => {
    setLoading(true);
    getDevice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDevice = () => {
    console.log(id);
    const token = localStorage.getItem("token");
    fetch(api_url + "/device/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 401) {
          navigate("/401");
        } else if (res.status === 404) {
          navigate("/404");
        } else if (res.status === 200) {
          return res.json();
        }
      })
      .then((data) => {
        console.log("devide.js", data);
        setDevice(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  if (loading) {
    return (
      <div className="container pt-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="container pt-5">
          <div className="card">
            <div class="card-header">
              <h4>{device.name}</h4>
              <ul class="nav nav-tabs card-header-tabs">
                <li class="nav-item">
                  <button
                    class={"nav-link" + (menu === "Dashboard" ? " active" : "")}
                    onClick={() => setMenu("Dashboard")}>
                    Dashboard
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    class={
                      "nav-link" + (menu === "Statistics" ? " active" : "")
                    }
                    onClick={() => setMenu("Statistics")}>
                    Statistics
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    class={"nav-link" + (menu === "Graphs" ? " active" : "")}
                    onClick={() => setMenu("Graphs")}>
                    Graphs
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    class={
                      "nav-link" + (menu === "Spot prices" ? " active" : "")
                    }
                    onClick={() => setMenu("Spot prices")}>
                    Spot prices
                  </button>
                </li>
                <li class="nav-item ms-auto">
                  <button
                    class={"nav-link" + (menu === "Settings" ? " active" : "")}
                    onClick={() => setMenu("Settings")}>
                    Settings
                  </button>
                </li>
              </ul>
            </div>
            <div className="card-body">
              {menu === "Dashboard" ? <Dashboard id={id} /> : null}
              {menu === "Statistics" ? <Statistics id={id} /> : null}
              {menu === "Graphs" ? <Graphs id={id} /> : null}
              {menu === "Spot prices" ? <Spot id={id} /> : null}
              {menu === "Settings" ? <Settings id={id} /> : null}
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Device;

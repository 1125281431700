import React from "react";
import "./switch.css";

const Switch = ({ isToggled, onToggle }) => {
  return (
    <>
      <label class="switch">
        <input type="checkbox" checked={isToggled} onChange={onToggle} />
        <span class="slider round"></span>
      </label>
    </>
  );
};

export default Switch;

import React from "react";

export const Statistics = () => {
  return (
    <>
      <div className="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">Statistics</h1>
        <p className="mb-4">
          This page is under construction. Please check back later.
        </p>
      </div>
    </>
  );
};

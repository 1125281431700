import React, { useState } from "react";

const Configurator = (props) => {
  const [ssid, setSsid] = useState(null);
  const [password, setPassword] = useState(null);

  const saveWifiConfig = () => {
    console.log("saveWifiConfig");
    let data = {
      wifi_setup: {
        ssid: ssid,
        password: password,
      },
    };
    console.log("data", JSON.stringify(data));
    props.writeData(JSON.stringify(data));
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Configurator</h4>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="ssid">SSID</label>
              <input
                type="text"
                className="form-control"
                id="ssid"
                placeholder="SSID"
                value={ssid}
                onChange={(e) => setSsid(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="text"
                className="form-control"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
        </div>
        <button className="btn btn-primary" onClick={saveWifiConfig}>
          Save
        </button>
      </div>
    </div>
  );
};

export default Configurator;
